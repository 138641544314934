import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVDateTimePicker from "components/ubold/molecules/forms/CVDateTimePicker";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVSimpleManyToMany from "components/ubold/molecules/forms/CVSimpleManyToMany";
import CVTextView from "components/ubold/molecules/forms/CVTextView";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";

import moment from "moment";
import definitions from "./definitions";
import useGlobalState from "state";
import utils from "utils";

function DiscountMasterAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState: any = {
    category: 1,
    tenant_category: 1,
    tenant_type: 1,
    all_building: true,
    budget_period: 1,
    budget_allocation: 0,
    budget_spent: 0,
    percentage: 0,
    minimum_monthly_commitment: 0,
    one_time: false,
    show: false,
    adjustment_order: false,
  };
  const today = moment().format("YYYY-MM-DD");

  const [state, setState] = useState(initialState);
  const [constants] = useGlobalState("constant_values");

  const isAllBuilding = state["all_building"] ?? false;
  const [user] = useGlobalState("user");

  const getReadOnlyStatus = (
    role: string,
    adjustmentOrder: boolean
  ) => {
    /** Let component use current FAL checking */
    if (user?.is_super_user) return null;

    const roleHFPA = "Head of FP&A";
    const roleAdjustmentDiscount = "Adjustment Discount";
    const result = role.split(",");

    const trimmedRoles = result.map((role) => role.trim());
    const hasRoleHFPA = trimmedRoles.includes(roleHFPA);
    const hasRoleAdjDiscount = trimmedRoles.includes(
      roleAdjustmentDiscount
    );

    if (hasRoleHFPA && !id) return true;
    if (hasRoleAdjDiscount && !id && adjustmentOrder) return false;
  };

  useEffect(() => {
    if (!state["start_date"] || id) return;
    /** update active value conditionally */
    setState((prevState: any) => ({
      ...prevState,
      active: state["start_date"] <= today,
    }));
  }, [state["start_date"], id]);

  useEffect(() => {
    if (!state["all_building"]) return;
    setState((prevState: any) => ({
      ...prevState,
      building_eligibility: [],
    }));
  }, [state["all_building"]]);

  useEffect(() => {
    if (!state?.minimum_monthly_commitment && state?.one_time) {
      setState((prevState: any) => ({
        ...prevState,
        minimum_monthly_commitment: 0,
      }));
    }
    if (state?.one_time) {
      setState((prevState: any) => ({
        ...prevState,
        percentage: 100,
      }));
    }
  }, [state?.minimum_monthly_commitment, state?.one_time]);

  useEffect(() => {
    if (id) return;
    setState((prevState: any) => ({
      ...prevState,
      budget_allocation: 0,
      adjustment_remarks: null,
    }));
  }, [state.adjustment_order, id]);

  useEffect(() => {
    if (state?.category === 4) {
      setState((prevState: any) => ({
        ...prevState,
        one_time: true,
      }));
    }
  }, [state?.category]);

  // effect to set the value of voucher_duration_day to 0 when one_time=true
  useEffect(() => {
    if (state?.one_time) {
      setState((prevState: any) => ({
        ...prevState,
        voucher_duration_day: 0,
      }));
    }
  }, [state?.one_time]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          isRequired
          readOnly={id}
        />
        <CVDateTimePicker
          name="start_date"
          title="Start Date"
          type="date"
          isRequired
          readOnly={id}
          minDate={
            !id && today
          } /** disable minDate if id is not null/undefined */
        />
        <CVDateTimePicker
          name="end_date"
          title="End Date"
          type="date"
          isRequired
          readOnly={id}
          minDate={!id && today}
        />
        <CVCheckBox name="active" title="Active" />
        <CVSelect
          name="category"
          title="Category"
          data={[
            { label: "Discount", value: 1 },
            { label: "Promo", value: 2 },
            { label: "Discount Voucher", value: 3 },
            { label: "Apology Voucher", value: 4 },
          ]}
          isRequired
          readOnly={id}
        />
        <CVTextField
          name="percentage"
          title="Percentage"
          type="number"
          isRequired
          readOnly={id || state?.one_time}
        />
        <CVTextField
          name="maximum_amount"
          title="Maximum Amount"
          type="number"
          isRequired
          readOnly={id}
          useCurrencyFormatHelper
        />
        <CVSelect
          name="tenant_category"
          title="Tenant Category"
          data={[
            { label: "All", value: 1 },
            { label: "Rukita Tenant", value: 2 },
            { label: "Legacy Tenant", value: 3 },
            { label: "Rukita Staff - Pay", value: 4 },
          ]}
          isRequired
          readOnly={id}
        />
        <CVSelect
          name="tenant_type"
          title="Tenant Type"
          data={constants?.discount_tenant_type}
          isRequired
          readOnly={id}
        />
        <CVSimpleManyToMany
          name="building_eligibility"
          title="Building Eligibility"
          data="building/building?__type__=select_entries&eligible_for_discount=true&limit=1500"
          readOnly={isAllBuilding || id}
          itemsExtractor={(row: {
            id: string;
            building_name: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
          customWarning={
            isAllBuilding &&
            "Available when All Building is unchecked"
          }
        />
        <CVCheckBox
          name="all_building"
          title="All Building"
          readOnly={id}
        />
        <CVTextField
          name="budget_allocation"
          title="Budget Allocation"
          type="number"
          isRequired
          min={0}
          useCurrencyFormatHelper
          readOnly={getReadOnlyStatus(
            utils.store.get("userRole"),
            state.adjustment_order
          )}
        />
        <CVTextView name="budget_spent" title="Budget Spent" />
        <CVSelect
          name="budget_period"
          title="Budget Period"
          data={[
            { label: "All", value: 1 },
            { label: "Monthly", value: 2 },
          ]}
          isRequired
          min={0}
          readOnly={id}
        />
        <CVCheckBox
          name="adjustment_order"
          title="Adjustment Order"
          readOnly={id}
        />
        <CVCheckBox
          name="one_time"
          title="One-Time"
          readOnly={
            id || state?.minimum_monthly_commitment > 0 || state?.show
          }
          customWarning={
            state?.minimum_monthly_commitment > 0 &&
            "only available when Monthly Commitment is 0"
          }
        />
        {state.adjustment_order ? (
          <CVTextArea
            name="adjustment_remarks"
            title="Adjust Remarks"
            readOnly={id}
          />
        ) : (
          <></>
        )}
        <CVCheckBox
          name="show"
          title="Show"
          readOnly={id || !state?.["active"] || state?.one_time}
        />
        <CVTextField
          name="minimum_monthly_commitment"
          title="Minimum Monthly Commitment"
          type="number"
          readOnly={id || state?.one_time}
        />
        <CVTextView name="date_created" title="Date Created" />

        {state?.category >= 3 ? (
          <CVTextField
            name="voucher_name"
            title="Voucher Name"
            maxLength={30}
            isRequired
            readOnly={id}
          />
        ) : (
          <></>
        )}

        {state?.category >= 3 ? (
          <CVTextField
            name="voucher_code"
            title="Voucher Code"
            maxLength={20}
            isRequired
            readOnly={id}
            uppercase
          />
        ) : (
          <></>
        )}

        {state?.category >= 3 ? (
          <CVTextField
            name="voucher_tnc_url"
            title="Voucher T&C URL"
            isRequired
            readOnly={id}
          />
        ) : (
          <></>
        )}

        {state?.category >= 3 ? (
          <CVTextField
            name="sort_priority"
            title="Sort Priority"
            isRequired
            type="number"
            readOnly={id}
          />
        ) : (
          <></>
        )}

        {state?.category >= 3 ? (
          <CVCheckBox
            name="is_visible"
            title="Is Visible?"
            readOnly={id}
          />
        ) : (
          <></>
        )}

        {state?.category >= 3 ? (
          // duration of how long the voucher is valid, in day unit
          <CVTextField
            name="voucher_duration_day"
            title="Voucher Max Duration (Day)"
            isRequired
            type="number"
            readOnly={id}
          />
        ) : (
          <></>
        )}
      </Form>
    </Page>
  );
}

export default withRouter(DiscountMasterAddEditPage);
