import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";

import utils from "../../../../../../utils";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";
import moment from "moment";

import InvoiceTenantDetail from "../InvoiceDetails/index";
import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import CHMultiRowView from "../../../../../../components/ubold/molecules/forms/CHMultiRowView";
import CHSingleRow from "../../../../../../components/ubold/molecules/forms/CHSingleRow";
import CHHiddenTextView from "components/ubold/molecules/forms/CHHiddenTextView";
import configs from "../../../../../../configs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CButton from "components/ubold/atoms/buttons/CButton";
import useGlobalState from "state";
import CButtonWithAlert from "../../../../../../components/ubold/atoms/buttons/CButtonWithAlert";

function Invoice(props) {
  const { id, definitions, tab, state, setState } = props;
  const todayPlusOne = moment().add(1, "days").format("YYYY-MM-DD");
  const [minEndDate, setMinEndDate] = useState(todayPlusOne);
  const sweetAlert = withReactContent(Swal);
  const [accesses] = useGlobalState("accesses");
  const isPaymentLinkCannotBeRendered =
    state?.invoice_status !== 1 ||
    state?.invoice_type === 3 ||
    (state?.invoice_type === 1 && state?.source === 1) ||
    state?.original___release_status !== 3;
  /**
   * payment link will not be rendered if:
   * - invoice status is not waiting for payment || 1
   * - invoice type is deposit (invoice type === 3)
   * - invoice type is main but created by system (invoice type === 1 && source === 1)
   */

  const renderPaymentLink = () => {
    if (isPaymentLinkCannotBeRendered) return <></>;
    return (
      <CHHiddenTextView
        name="payment_link_url"
        title="Payment Link"
        toastMessage={
          state?.payment_link?.status !== 3 &&
          state?.payment_link_url &&
          "Payment link copied to clipboard"
        }
        allowedToShowHiddenText={
          state?.payment_link?.status !== 3 && state?.payment_link_url
        }
        buttonTitle={
          state?.payment_link?.status !== 3 && state?.payment_link_url
            ? "Click to show"
            : "Click to generate payment link"
        }
        handleOnClick={() => {
          if (
            state?.payment_link?.status !== 3 &&
            state?.payment_link_url
          ) {
            setTimeout(() => {
              const copiedText = document.getElementById(
                "CTextField-payment_link_url"
              );
              copiedText.select();
              copiedText.setSelectionRange(0, 99999);
              navigator.clipboard.writeText(copiedText.value);
              utils.httpClient.get(
                configs.apiUrl +
                  `finances_new/view-payment-link/${id}/`,
                {},
                () => {},
                () => {}
              );
            }, 1000);
          } else {
            utils.httpClient.post(
              configs.apiUrl + "finances_new/create-payment-link/",
              {
                invoice_id: id,
                code_bank: state?.code_bank,
              },
              (data) => {
                if (!data?.payment_url)
                  throw new Error(
                    "create payment link success but not receive any url"
                  );
                sweetAlert.close();
                sweetAlert.fire(
                  "Success",
                  "Payment link has been requested successfully.",
                  "success"
                );
                setState((prevState) => ({
                  ...prevState,
                  payment_link_url: data.payment_url,
                  payment_link: {
                    status: 1,
                    status_label: "Waiting",
                    url: data.payment_url,
                  },
                }));
              },
              () => {
                sweetAlert.close();
                sweetAlert.fire(
                  "Failed",
                  "Payment link request is unsuccessful. Please try again",
                  "error"
                );
              }
            );
          }
        }}
      >
        {state?.payment_link?.status !== 3 &&
          state?.payment_link_url && (
            <div className="col-md-3">
              <CButton
                title="Cancel Payment Link"
                onClick={() => {
                  sweetAlert.close();
                  sweetAlert
                    .fire({
                      html: "Are you sure want to cancel payment link?",
                      icon: "warning",
                      confirmButtonText: "Yes",
                      denyButtonText: "No",
                      showDenyButton: true,
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        utils.httpClient.post(
                          configs.apiUrl +
                            "finances_new/cancel-payment-link/",
                          {
                            invoice_id: id,
                          },
                          () => {
                            Swal.fire(
                              "Canceled",
                              "Successfully cancel the payment link",
                              "success"
                            );
                            setState((prevState) => ({
                              ...prevState,
                              payment_link_url: null,
                              payment_link: {
                                ...state?.payment_link,
                                url: null,
                                status: 3,
                                status_label: "Cancel",
                              },
                            }));
                          },
                          () => {
                            sweetAlert.close();
                            sweetAlert.fire(
                              "Failed",
                              "Payment link cancellation is unsuccessful. Please try again",
                              "error"
                            );
                          }
                        );
                      }
                    });
                }}
              />
            </div>
          )}
      </CHHiddenTextView>
    );
  };

  const renderBankNameSelection = () => {
    if (
      isPaymentLinkCannotBeRendered ||
      (state?.payment_link?.status !== 3 && state?.payment_link_url)
    )
      return <></>;
    return (
      <CHAsyncSelect
        name="code_bank"
        title="Bank Name"
        data="payment_gateway/enabled_payment?__type__=select_entries&status=1"
        isRequired
        isClearable={false}
        state={state}
        setState={setState}
        itemsExtractor={(row) => ({
          label: row.name,
          value: row.code,
        })}
      />
    );
  };

  useEffect(() => {
    if (!state?.started_at) {
      setMinEndDate(todayPlusOne);
      return;
    }
    const newMinEndDate = moment(state?.started_at)
      .add(1, "day")
      .format("YYYY-MM-DD");
    setMinEndDate(newMinEndDate);
    return;
  }, [state?.started_at]);

  const tempDetail = state?.detail ? [...state?.detail] : [];
  if (tempDetail.length > 0) {
    tempDetail.forEach((detail) => {
      const period = Object.keys(detail)[0];
      const periodData = detail[period];
      state[`${period}-default`] = periodData;
    });
  }

  const generalKeys = state?.detail?.map(
    (tempDetail) => Object.keys(tempDetail)[0]
  );

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      state={state}
      setState={setState}
      readOnly={state?.invoice_status === 2}
      enableDownloadBtn
      downloadTitleBtn="Download Invoice"
      downloadLink={state?.invoice_pdf_url}
      additionalButtonsBeforeMainButtons={[
        accesses?.includes("finance_new.invoice.force_paid") && id ? (
          <CButtonWithAlert
            baseLink="finances_new/mark-as-paid/"
            confirmBtnText="Yes, I'm sure"
            dialogText="Are you sure to change this Invoice status without any payment?"
            dialogTitle="Mark Invoice as Paid"
            failedMessage="Mark this invoice as paid is unsuccessful. Please try again"
            key="max-invoice-0"
            paramObject={{ invoice_id: id }}
            successMessage="Successfully mark this invoice as Done"
            title="Mark as Paid"
            withDialog
            inputLabel="Please input your reason (required)"
            inputType="textarea"
            inputPlaceholder="Type your reason here..."
            inputFieldName="mark_as_paid_remark"
          />
        ) : (
          <></>
        ),
      ]}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        {!id ? (
          <></>
        ) : (
          <CHTextView title="Invoice ID" name="number" />
        )}
        <CHSelect
          name="invoice_type"
          title="Invoice Type"
          readOnly
          data={{ optionField: "finance_new_invoice_type" }}
        />

        <CHAsyncSelect
          name="order"
          title="Order ID"
          data="order_new/order?__type__=select_entries&with_related=1"
          isRequired
          itemsExtractor={(row) => {
            // set selected order from endpoint response
            if (state?.order === row.id) {
              setState((prev) => ({
                ...prev,
                is_migration_data: row.is_migration_data,
              }));
            }

            return {
              label: row.number,
              value: row.id,
              building_name: row.building__building_name,
              tenant_name: row.tenant__name,
              room_name: row.room__name,
              is_migration_data: row.is_migration_data,
            };
          }}
          afterChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              building_name: value?.building_name,
              tenant_name: value?.tenant_name,
              room_name: value?.room_name,
              is_migration_data: value?.is_migration_data,
            }));
          }}
          readOnly={id}
        />

        {!id ? (
          <CHDateTimePicker
            name="started_at"
            title="Started At"
            type="date"
            isRequired
            minDate={moment().format("YYYY-MM-DD")}
            readOnly
          />
        ) : (
          <CHTextView
            readOnly
            title="Started At"
            name="started_at"
            isRequired
            renderer={(value) => {
              return utils.formatter.date(value);
            }}
          />
        )}
        {!id ? (
          <CHDateTimePicker
            name="ended_at"
            title="Ended At"
            type="date"
            isRequired
            minDate={minEndDate}
            maxDate={moment(state?.started_at)
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD")}
            readOnly
          />
        ) : (
          <CHTextView
            readOnly
            title="Ended At"
            name="ended_at"
            renderer={(value) => {
              return utils.formatter.date(value);
            }}
          />
        )}
        <CHTextView
          title="Building Name"
          name="building_name"
          readOnly
        />
        <CHTextView title="Room Name" name="room_name" readOnly />
        <CHTextView title="Tenant Name" name="tenant_name" readOnly />
        {!id ? (
          <></>
        ) : (
          <CHTextView
            readOnly
            title="Grand Total"
            name="grand_total"
            renderer={(value) => {
              return utils.formatter.currency(value);
            }}
          />
        )}
        <CHSelect
          title="Release Status"
          name="release_status"
          data={{
            optionField: "finance_new_invoice_release_status",
          }}
          readOnly={
            state?.invoice_status === 2 ||
            state?.original___release_status === 3
          }
        />

        {!id ? (
          <CHSelect
            name="category"
            title="Invoice Category"
            readOnly
            data={{
              optionField: "finance_new_invoice_detail_category",
            }}
          />
        ) : (
          <></>
        )}
        {!id ? (
          <CHSelect
            name="sub_category"
            title="Invoice Sub Category"
            readOnly
            data={{
              optionField: "finance_new_invoice_detail_sub_category",
            }}
          />
        ) : (
          <></>
        )}
        {!id ? (
          <></>
        ) : (
          <CHSelect
            title="Invoice Status"
            name="invoice_status"
            readOnly
            data={{
              optionField: "finance_new_invoice_status",
            }}
          />
        )}
        {!id ? (
          <></>
        ) : (
          <CHCheckBox
            title="First Invoice"
            name="first_invoice"
            readOnly
          />
        )}
        {renderPaymentLink()}
        {renderBankNameSelection()}
      </TabPaneSection>
      {!id ? (
        <section
          style={{
            width: "100%",
            background: "#f2f2f2",
            height: 30,
            paddingRight: 24,
            paddingLeft: 29,
            alignItems: "center",
            fontSize: 16,
            fontWeight: 500,
            display: "flex",
          }}
        >
          Invoice Detail
        </section>
      ) : (
        <></>
      )}

      {!id ? (
        <COneOrManyToManyFieldV2
          id={id}
          name="t_invoice_details"
          saveOnlyFromMainForm
          relationKey="invoice_id"
          mainTab={true}
          columns={[
            {
              name: "item",
              title: "Invoice Item Name",
              type: "text",
              isRequired: true,
            },
            {
              name: "price",
              type: "number",
              title: "Invoice Item Amount (Rp)",
              isRequired: true,
              useCurrencyHelper: true,
            },
          ]}
        />
      ) : (
        <TabPaneSection accordionId="formAccordion">
          {generalKeys?.map((key, index) => {
            return (
              <InvoiceTenantDetail
                key={`${key}-${index}`}
                generalKey={key}
              />
            );
          })}
        </TabPaneSection>
      )}
      {id &&
      state?.referal &&
      Object.keys(state?.referal).length > 0 ? (
        <CHMultiRowView
          data={state?.referal}
          title="Referral"
          isDeduction
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {id && state?.vouchers?.length > 0 ? (
        <CHMultiRowView
          data={state?.vouchers}
          title="Voucher"
          multi={true}
          isDeduction
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {id ? (
        <CHSingleRow
          title="Grand Total"
          data={state?.grand_total}
          moreLeft
          titleWidth="57%"
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </TabPane>
  );
}

export default withRouter(Invoice);
